import Nav from "./Nav";
import RedX from "./assets/red_x.svg";
import CartAndBox from "./assets/cart_box.svg";
import GreenCart from "./assets/green_cart.svg";
import CartGraph from "./assets/carts_graph.svg";
import GreenCheck from "./assets/green_check.svg";
import SearchIcon from "./assets/search_icon.svg";
import FailsGraph from "./assets/fails_graph.svg";
import React, { useEffect, useState } from "react";
import CheckoutGraph from "./assets/checkouts_graph.svg";
import axios from "axios";

let authToken = "";
let parsedData = {};

try {
    const token = document.cookie
        .split("; ")
        .find((row) => row.startsWith("token="));
    if (token) {
        authToken = token.split("=")[1];

        const payload = authToken.split(".")[1];
        const decodedPayload = atob(payload);
        parsedData = JSON.parse(decodedPayload);

        const expiry = new Date(parsedData.expiry * 1000);
        const now = new Date();

        if (now > expiry) {
            document.cookie = "";
            throw new Error("Token expired");
        }
    } else {
        throw new Error("Token not found");
    }
} catch (e) {
    console.error(e);
}

const axiosInstance = axios.create({
    baseURL: "https://api.cartlybot.io",
    headers: {
        Authorization: authToken,
    },
});

const fetchCheckouts = async () => {
    try {
        const response = await axiosInstance.get(
            `/getCheckouts/${parsedData.userId}`
        );
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error fetching checkouts:", error);
    }
};

const startCheckout = async (cartId) => {
    try {
        const response = await axiosInstance.get(
            `/startAco/${parsedData.userId}/${cartId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error starting checkout:", error);
    }
};

const getAnalytics = async () => {
    try {
        const response = await axiosInstance.get(
            `/analytics/${parsedData.userId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching analytics:", error);
    }
};

function Carts() {
    const [searchTerm, setSearchTerm] = useState("");
    const [sectionSearch, setSectionSearch] = useState("");
    const [quantitySearch, setQuantitySearch] = useState("");
    const [sortOption, setSortOption] = useState("name"); // Default sort option set to "name"
    const [analytics, setAnalytics] = useState({
        active_carts: 0,
        failed_carts: 0,
        success_carts: 0,
        total_carts: 0,
    });

    useEffect(() => {
        const fetchAnalytics = async () => {
            const analyticsData = await getAnalytics(parsedData.userId);
            setAnalytics(
                analyticsData || {
                    active_carts: 0,
                    failed_carts: 0,
                    success_carts: 0,
                    total_carts: 0,
                }
            );
        };
        fetchAnalytics();
    }, []);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchCheckouts(parsedData.userId);
            if (data !== null) {
                setRows(data);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 2000);

        return () => clearInterval(intervalId);
    }, []);

    const filteredRows = rows
        ? rows
              .filter(
                  (row) =>
                      row.event_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) &&
                      row.section
                          .toLowerCase()
                          .includes(sectionSearch.toLowerCase()) &&
                      row.quantity.toString().includes(quantitySearch)
              )
              .sort((a, b) => {
                  if (sortOption === "name") {
                      return a.event_name.localeCompare(b.event_name);
                  } else if (sortOption === "price") {
                      return b.price - a.price;
                  }
                  return 0;
              })
        : [];

    return (
        <div className="flex flex-row">
            <Nav />
            <div className="h-screen w-screen p-5 text-white pl-10 pr-10">
                <header className="flex justify-between items-center mb-5">
                    <h1 className="text-sm font-medium flex flex-row gap-2 items-center">
                        <img src={GreenCart} alt="Cart Graph" />
                        My Carts
                    </h1>
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => {
                                document.cookie =
                                    "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                                window.location.reload();
                            }}
                            className="flex items-center gap-2"
                        >
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.90625 16.0312C8.90625 16.1887 8.84369 16.3397 8.73234 16.4511C8.621 16.5624 8.46997 16.625 8.3125 16.625H3.5625C3.40503 16.625 3.25401 16.5624 3.14266 16.4511C3.03131 16.3397 2.96875 16.1887 2.96875 16.0312V2.96875C2.96875 2.81128 3.03131 2.66026 3.14266 2.54891C3.25401 2.43756 3.40503 2.375 3.5625 2.375H8.3125C8.46997 2.375 8.621 2.43756 8.73234 2.54891C8.84369 2.66026 8.90625 2.81128 8.90625 2.96875C8.90625 3.12622 8.84369 3.27724 8.73234 3.38859C8.621 3.49994 8.46997 3.5625 8.3125 3.5625H4.15625V15.4375H8.3125C8.46997 15.4375 8.621 15.5001 8.73234 15.6114C8.84369 15.7228 8.90625 15.8738 8.90625 16.0312ZM17.0451 9.07992L14.0763 6.11117C13.9933 6.02804 13.8875 5.97142 13.7722 5.94847C13.657 5.92552 13.5375 5.93728 13.429 5.98226C13.3204 6.02723 13.2277 6.10341 13.1624 6.20114C13.0972 6.29886 13.0624 6.41375 13.0625 6.53125V8.90625H8.3125C8.15503 8.90625 8.004 8.96881 7.89266 9.08016C7.78131 9.1915 7.71875 9.34253 7.71875 9.5C7.71875 9.65747 7.78131 9.8085 7.89266 9.91985C8.004 10.0312 8.15503 10.0938 8.3125 10.0938H13.0625V12.4688C13.0624 12.5863 13.0972 12.7011 13.1624 12.7989C13.2277 12.8966 13.3204 12.9728 13.429 13.0177C13.5375 13.0627 13.657 13.0745 13.7722 13.0515C13.8875 13.0286 13.9933 12.972 14.0763 12.8888L17.0451 9.92008C17.1003 9.86493 17.1441 9.79945 17.174 9.72737C17.2038 9.65529 17.2192 9.57803 17.2192 9.5C17.2192 9.42197 17.2038 9.34471 17.174 9.27263C17.1441 9.20055 17.1003 9.13507 17.0451 9.07992Z"
                                    fill="white"
                                />
                            </svg>
                            Welcome, {parsedData.userName}
                        </button>
                        <img
                            src={parsedData.avatar}
                            alt="User Avatar"
                            className="w-8 h-8 rounded-full"
                        />
                    </div>
                </header>
                <div className="grid grid-cols-3 gap-4 mb-5 w-[70%] text-[8px] text-[#495064] font-bold tracking-widest">
                    <div className="flex justify-between bg-input p-4 rounded items-center">
                        <div className="flex flex-col items-start gap-2">
                            <img src={CartAndBox} alt="Green Cart" />
                            <div className="flex flex-col gap-2">
                                <h2 className="text-lg text-white">
                                    {analytics.total_carts}
                                </h2>
                                <p className="text-[8px] text-[#495064]">
                                    TOTAL CARTS
                                </p>
                            </div>
                        </div>
                        <img src={CartGraph} alt="Cart Graph" />
                    </div>
                    <div className="flex justify-between bg-input p-4 rounded items-center">
                        <div className="flex flex-col items-start gap-2">
                            <img src={GreenCheck} alt="Green Check" />
                            <div className="flex flex-col gap-2">
                                <h2 className="text-lg text-white">
                                    {analytics.success_carts}
                                </h2>
                                <p className="text-[8px] text-[#495064]">
                                    TOTAL CHECKOUTS
                                </p>
                            </div>
                        </div>
                        <img src={CheckoutGraph} alt="Checkout Graph" />
                    </div>
                    <div className="flex justify-between bg-input p-4 rounded items-center">
                        <div className="flex flex-col items-start gap-2">
                            <img src={RedX} alt="Green Check" />
                            <div className="flex flex-col gap-2">
                                <h2 className="text-lg text-white">
                                    {analytics.failed_carts}
                                </h2>
                                <p className="text-[8px] text-[#495064]">
                                    TOTAL FAILS
                                </p>
                            </div>
                        </div>
                        <img src={FailsGraph} alt="Fails Graph" />
                    </div>
                </div>
                <div className="p-4 rounded mb-5">
                    <div className="flex gap-4 mb-4">
                        <div className="flex flex-row gap-2 items-center">
                            <img src={GreenCart} alt="Green Cart" />
                            Carts
                        </div>
                        <div className="relative w-1/3">
                            <input
                                type="text"
                                placeholder="Event name"
                                className="bg-input p-2 pl-10 rounded text-[#5E657A] focus:outline-none w-full"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <img
                                src={SearchIcon}
                                alt="Search Icon"
                                className="w-4 h-4 absolute left-3 top-3"
                            />
                        </div>
                        <input
                            type="text"
                            placeholder="Section"
                            className="bg-input p-2 rounded focus:outline-none"
                            value={sectionSearch}
                            onChange={(e) => setSectionSearch(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Quantity"
                            className="bg-input p-2 rounded focus:outline-none"
                            value={quantitySearch}
                            onChange={(e) => setQuantitySearch(e.target.value)}
                        />
                        <div className="relative inline-flex">
                            <select
                                className="rounded h-10 pl-10 pr-14 bg-input focus:outline-none appearance-none"
                                value={sortOption}
                                onChange={(e) => setSortOption(e.target.value)}
                            >
                                <option>Sort by</option>
                                <option value="price">Price</option>
                                <option value="name">Name</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.25 5.25L7.5 11.5L13.75 5.25L12.6406 4.14063L7.5 9.28125L2.35938 4.14063L1.25 5.25Z"
                                        fill="#5E657A"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <hr className="border-t border-gray-600 my-4 opacity-10" />
                </div>
                <div className="grid grid-cols-7 p-2 tracking-widest text-[10px] text-[#495064]">
                    <div>EVENT</div>
                    <div>QUANTITY</div>
                    <div>TOTAL PRICE</div>
                    <div>SEATS</div>
                    <div>SECTION</div>
                    <div>STATUS</div>
                    <div>ACO</div>
                </div>
                <div className="overflow-y-auto h-96 relative">
                    <div className="absolute inset-0">
                        {filteredRows.map((row, index) => (
                            <div
                                key={index}
                                className={`grid grid-cols-7 gap-4 p-2 tracking-widest text-[12px] text-white ${
                                    index % 2 === 0 ? "bg-input" : ""
                                } p-4 mb-2 rounded`}
                            >
                                <div className="text-[12px]">
                                    {row.event_name.length > 15
                                        ? `${row.event_name.substring(
                                              0,
                                              15
                                          )}...`
                                        : row.event_name}
                                </div>
                                <div className="text-[12px]">
                                    {row.quantity}
                                </div>
                                <div className="text-[12px]">
                                    {row.price.toLocaleString("de-DE", {
                                        style: "currency",
                                        currency: "EUR",
                                    })}
                                </div>
                                <div className="flex gap-2 text-[12px]">
                                    {row.seats.length > 15
                                        ? `${row.seats.substring(0, 15)}...`
                                        : row.seats}
                                    <div className="relative group">
                                        <button>
                                            <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18.355 9.25953C18.3291 9.2009 17.7004 7.80633 16.3029 6.40879C14.4407 4.54664 12.0887 3.5625 9.49999 3.5625C6.91124 3.5625 4.55925 4.54664 2.6971 6.40879C1.29956 7.80633 0.667962 9.20312 0.644954 9.25953C0.611194 9.33547 0.59375 9.41764 0.59375 9.50074C0.59375 9.58384 0.611194 9.66602 0.644954 9.74195C0.67093 9.80059 1.29956 11.1944 2.6971 12.592C4.55925 14.4534 6.91124 15.4375 9.49999 15.4375C12.0887 15.4375 14.4407 14.4534 16.3029 12.592C17.7004 11.1944 18.3291 9.80059 18.355 9.74195C18.3888 9.66602 18.4062 9.58384 18.4062 9.50074C18.4062 9.41764 18.3888 9.33547 18.355 9.25953ZM9.49999 12.4688C8.91283 12.4688 8.33885 12.2946 7.85064 11.9684C7.36244 11.6422 6.98192 11.1786 6.75723 10.6361C6.53253 10.0936 6.47374 9.49671 6.58829 8.92083C6.70284 8.34494 6.98558 7.81596 7.40077 7.40078C7.81596 6.98559 8.34494 6.70284 8.92082 6.58829C9.4967 6.47374 10.0936 6.53253 10.6361 6.75723C11.1786 6.98193 11.6422 7.36244 11.9684 7.85065C12.2946 8.33886 12.4687 8.91284 12.4687 9.5C12.4687 10.2874 12.156 11.0425 11.5992 11.5992C11.0425 12.156 10.2874 12.4688 9.49999 12.4688Z"
                                                    fill="#495064"
                                                />
                                            </svg>
                                        </button>
                                        <div className="absolute hidden group-hover:flex flex-col items-start bg-[#1D2336] shadow-lg rounded p-2 -left-10 z-50 text-white">
                                            <div className="flex flex-row items-center gap-1">
                                                <span className="text-[10px] text-[#484F63]">
                                                    Seats
                                                </span>
                                                <span className="text-[10px]">
                                                    {row.seats}
                                                </span>
                                            </div>
                                            <div className="flex flex-row items-center gap-1">
                                                <span className="text-[10px] text-[#484F63]">
                                                    Row
                                                </span>
                                                <span className="text-[10px]">
                                                    {row.row}
                                                </span>
                                            </div>
                                            <div className="flex flex-row items-center gap-1">
                                                <span className="text-[10px] text-[#484F63]">
                                                    Section
                                                </span>
                                                <span className="text-[10px]">
                                                    {row.section.length > 10
                                                        ? `${row.section.substring(
                                                              0,
                                                              5
                                                          )}...`
                                                        : row.section}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-[12px] relative group">
                                    <div className="flex items-center gap-2">
                                        {row.section.length > 15
                                            ? `${row.section.substring(
                                                  0,
                                                  15
                                              )}...`
                                            : row.section}
                                    </div>
                                    {row.section.length > 15 && (
                                        <div className="absolute hidden group-hover:flex flex-col items-start bg-[#1D2336] shadow-lg rounded p-2 -left-10 -bottom-8 z-50 text-white">
                                            <span className="text-[10px]">
                                                {row.section}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`${
                                        row.status
                                            .toLowerCase()
                                            .includes("successful")
                                            ? "text-[#7CF3A9]"
                                            : row.status
                                                  .toLowerCase()
                                                  .includes("failed")
                                            ? "text-[#FF3F3F]"
                                            : "text-[#FFC44E]"
                                    } text-[12px]`}
                                >
                                    {row.status}
                                </div>
                                <button
                                    className="flex justify-center items-center w-1/2 text-center rounded text-[12px] hover:opacity-40"
                                    style={{
                                        background:
                                            "linear-gradient(180deg, #63D78F 0%, #26C6B4 50%, #00BBCC 100%)",
                                    }}
                                    onClick={() => {
                                        console.log(
                                            "Starting ACO for",
                                            row.cart_id
                                        );
                                        startCheckout(row.cart_id);
                                    }}
                                >
                                    ACO
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Carts;
