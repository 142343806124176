import React from "react";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import { isLoggedIn } from "./utils";

function App() {
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Navigate
                            to={isLoggedIn() ? "/dashboard" : "/login"}
                            replace
                        />
                    }
                />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/dashboard"
                    element={
                        isLoggedIn() ? (
                            <Dashboard />
                        ) : (
                            <Navigate to="/login" replace />
                        )
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
